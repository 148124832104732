import { Children, useEffect, useState } from "react";
import { Row, Col } from "antd";
// import { Link } from "react-router-dom";
// import coursesData from "../courses/coursesData";
import { Container, Nav, Tab } from "react-bootstrap";
import { FaUserCog } from "react-icons/fa";
import { TbCertificate } from "react-icons/tb";
// import { MdFavorite } from "react-icons/md";
// import { SiBookstack } from "react-icons/si";
// import { CgAdd } from "react-icons/cg";
import AvatarImage from "../../assets/avatar.png";
import StudentSettingsTab from "./StudentSettingsTab/StudentSettingsTab";
import "./Students.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import SharedModal from "../shared/SharedModal/SharedModal";
import axios from "axios";
import { openNotification } from "../shared/Notification";
import { fetchCurrentUser } from "../../api";

const StudentSettings = () => {

  const userCourses = useSelector(state => {
    return state.myCourses;
  });
  const { userInfo } = useSelector(state => {
    return state.userInfo;
  });

  const [showModal, setShowModal] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  // Handlers to open and close the modal
  const handleShowModal = () => {
    setShowModal(true);
    console.log(handleShowModal);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    console.log(handleShowModal);
  };

  const token = localStorage.getItem('AUTH_TOKEN')

  const confirmUnsubscribtion = async () => {
    try {
      var response = await axios.post(`https://dashboard.codinghut.com/api/unsubscribe`, token)
      console.log(response, "unsubscribe");
      openNotification({
        message: `${response.data.message}`,
        isNotError: true
      })
    } catch (error) {
      console.error(error);
      openNotification({
        message: `${response.data.message}`,
        isNotError: false,
      })
    } finally {
      handleCloseModal();
    }
  };

  // handle when the user subscripe or not 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchCurrentUser();
        setCurrentUser(response.data);
      } catch (error) {
        console.error('Error fetching current user:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <section className="settings-page">
      {/* Using the Shared Modal Component */}
      <SharedModal
        show={showModal}
        handleClose={handleCloseModal}
        title="إلغاء الاشتراك"
        body={
          <div>
            <h4> هل انت متأكد من إلغاء الاشتراك ؟ </h4>{" "}
            <p> في حاله الغاء الاشتراك سيتم فقد الخصم الحالي </p>{" "}
          </div>
        }
        footerButtons={[
          {
            label: "تأكيد",
            className: "confirm",
            onClick: () => {
              confirmUnsubscribtion();
            },
          },
          {
            label: "تراجع",
            className: "cancel",
            onClick: handleCloseModal,
          },
        ]}
      />

      <Tab.Container id="left-tabs-example" defaultActiveKey="settings">
        <Container className="settings-tab__container">
          <Row className="settings-tab__row" gutter={32}>
            <Col xs={24} md={8} lg={6} className="settings__sidebar-col">
              <section className="settings__sidebar">
                <Nav variant="pills" className="flex-column">
                  <div className="settings__profile-image mb-3">
                    <figure>
                      <img src={AvatarImage} alt="logo-pic" />
                    </figure>
                    {userInfo.subscription_renew_date && (
                      <div className="d-flex flex-column align-items-center">
                        <span>تاريخ تجديد الإشتراك</span>
                        <span>{userInfo.subscription_renew_date}</span>
                      </div>
                    )}
                  </div>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="settings"
                      className="d-flex align-items-center sidebar__item"
                    >
                      <FaUserCog className="me-2"></FaUserCog>
                      <div className="ms-2">الإعدادات</div>
                    </Nav.Link>
                  </Nav.Item>
                  <div className="nav-item p-3">
                    {currentUser?.is_subscribed ? <button
                      onClick={handleShowModal}
                      className="custom__navLink nav-link px-0"
                    >
                      الغاء الاشتراك
                    </button> : <Link
                      to={`/Pricing`}
                    >
                      <button
                        className="custom__navLink nav-link px-0"
                      >
                        إشترك الان
                      </button>
                    </Link>}

                  </div>
                  {userCourses?.myCourses?.some(
                    ({ certificate }) => certificate
                  ) && (
                      <div className="certified__courses nav-item p-3">
                        <div className="certified__coursesTitle d-flex align-items-center">
                          <span>
                            <TbCertificate className="me-2" />
                          </span>
                          <h6 className="mb-0 ms-2">الشهادات</h6>
                        </div>
                        <ul className="list-unstyled d-flex flex-column">
                          {Children.toArray(
                            userCourses.myCourses.map(({ id, title }) => (
                              <Link
                                to={`/courses/${id}`}
                                className="custom__navLink nav-link"
                              >
                                <span>{title}</span>
                              </Link>
                            ))
                          )}
                        </ul>
                      </div>
                    )}
                  {/* <Nav.Item>
                    <Nav.Link
                      eventKey="saved-courses"
                      className="d-flex align-items-center sidebar__item"
                    >
                      <SiBookstack />
                      <div className="ms-2">الدورات المسجلة</div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="fav-courses"
                      className=" d-flex align-items-center sidebar__item"
                    >
                      <MdFavorite />
                      <div className="ms-2">الدورات المقترحة</div>
                    </Nav.Link>
                  </Nav.Item> */}
                </Nav>
              </section>
            </Col>
            <Col xs={24} md={14} lg={18} className="settings__content-col">
              <section className="settings__content ">
                <Tab.Content>
                  <Tab.Pane eventKey="settings" className="content__item ">
                    <StudentSettingsTab />
                  </Tab.Pane>
                  {/* <Tab.Pane eventKey="saved-courses">
                    <div className="content__item empty">
                      لا يوجد دورات مفضلة
                      <button className="btn btn-sm btn-primary ms-2 add-btn">
                        <CgAdd className="add-icon" />
                        إضافة دورة
                      </button>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="fav-courses">
                    <div className={`content__item ${modal ? "empty" : ""}`}>
                      {!modal ? (
                        <div>
                          لا يوجد دورات مفضلة
                          <button className="btn btn-sm btn-primary ms-2 add-btn">
                            <CgAdd className="add-icon" />
                            إضافة دورة
                          </button>
                        </div>
                      ) : (
                        <Row className="settings__course-cards" gutter={32}>
                          {coursesData.map(course => (
                            <Col xs={24} lg={12} key={course.id}>
                              <div className="card course__card p-2 my-2">
                                <Link to={`/courses/${course.id}`}>
                                  <img
                                    src={require(`../../assets/${course.img}`)}
                                    className="card-img-top course-img"
                                    alt={course.course}
                                  />
                                </Link>
                                <div className="card-body">
                                  <Link to={`/courses/${course.id}`}>
                                    <h5 className="card-title course__title">
                                      {course.course}
                                    </h5>
                                  </Link>
                                  <div>
                                    <small>
                                      <i className="fa-regular fa-clock px-1 text-primary"></i>
                                      50 ساعة
                                    </small>
                                    <small className="px-2">
                                      {course.lecturesCount} درس
                                    </small>
                                  </div>
                                  <p className="card-text my-3">
                                    {course.desc}
                                  </p>
                                  <div className="instructor d-flex align-items-center my-4">
                                    <div className="img"></div>
                                    <p>
                                      <Link
                                        to={"/profile"}
                                        className="text-dark"
                                      >
                                        {course.instructor}
                                      </Link>
                                    </p>
                                  </div>
                                  <div className="buttons d-flex justify-content-between">
                                    <button
                                      className="preview-course"
                                      onClick={() => setModal(true)}
                                    >
                                      مقدمة
                                    </button>
                                    <Link
                                      to={`/courses/${course.id}`}
                                      className="details-link"
                                    >
                                      تفاصيل الكورس
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      )}
                    </div>
                  </Tab.Pane> */}
                  {/* <Tab.Pane
                    eventKey="saved-courses"
                    className={`content__item ${true ? "empty" : ""}`}
                  >
                    <Row className="settings__course-cards" gutter={32}>
                      {coursesData.map((course) => (
                        <Col xs={24} lg={12}>
                          <div className="card course__card p-2 my-2">
                            <Link to={`/courses/${course.id}`}>
                              <img
                                src={require(`../../assets/${course.img}`)}
                                className="card-img-top course-img"
                                alt={course.course}
                              />
                            </Link>
                            <div className="card-body">
                              <Link to={`/courses/${course.id}`}>
                                <h5 className="card-title course__title">
                                  {course.course}
                                </h5>
                              </Link>
                              <div>
                                <small>
                                  <i className="fa-regular fa-clock px-1 text-primary"></i>
                                  50 ساعة
                                </small>
                                <small className="px-2">
                                  {course.lecturesCount} درس
                                </small>
                              </div>
                              <p className="card-text my-3">{course.desc}</p>
                              <div className="instructor d-flex align-items-center my-4">
                                <div className="img"></div>
                                <p>
                                  <Link to={"/profile"} className="text-dark">
                                    {course.instructor}
                                  </Link>
                                </p>
                              </div>
                              <div className="buttons d-flex justify-content-between">
                                <button
                                  className="preview-course"
                                  onClick={() => setModal(true)}
                                >
                                  مقدمة
                                </button>
                                <Link
                                  to={`/courses/${course.id}`}
                                  className="details-link"
                                >
                                  تفاصيل الكورس
                                </Link>
                              </div>
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </Tab.Pane> */}
                </Tab.Content>
              </section>
            </Col>
          </Row>
        </Container>
      </Tab.Container>
    </section>
  );
};

export default StudentSettings;

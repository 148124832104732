import axios from '../../api/axois-client';
import React from 'react'
import { useQuery } from "react-query";
import Slider from "react-slick";
import { FaChevronRight,FaChevronLeft,FaFacebookF, FaTwitter, FaInstagram, FaGithub } from "react-icons/fa";
import "./Team.scss";
import { Link } from 'react-router-dom';


function Team() {

  
const SampleNextArrow = (props) => {
  const { onClick } = props;

  return (
    <button onClick={onClick} className="slider__arrowLeft">
      <FaChevronLeft />
    </button>
  );
}

const SamplePrevArrow = (props) => {
  const { onClick } = props;

  return (
    <button onClick={onClick} className="slider__arrowRight">
      <FaChevronRight />
    </button>
  );
}

  const getTeamData = async () => {
    const { data } = await axios.get(`/lecturers`);
    return data;
  };

  const { data, isLoading } = useQuery('teamData', getTeamData);
  console.log(data?.data);


  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],

  };


  return (
    <>
      <section className='team'>
        {!isLoading && data?.data && (
          <Slider {...settings}>
            {data.data.map((item) => (
              <div key={item.id} >
                <div className=" team-member p-4 mx-3 bg-white ">
                  <Link to={`/lecturer/${item.id}`} className="text-dark" >
                    <div >
                      <img className="w-100 rounded-4 " src={item.photo} alt={item.name} />
                      <h4>{item.position}</h4>
                      <h3>{item.name}</h3>
                      <p>{item.bio.split(' ').slice(0, 15).join(' ')}...</p>
                    </div>
                  </Link>

                  <div className="icons mt-3">
                    {item.facebook_link ? <a href={item.facebook_link}>
                      <FaFacebookF className="icon-facebook" />
                    </a> : " "}
                    {item.twitter_link ? <a href={item.twitter_link}>
                      <FaTwitter className="icon-twitter" />
                    </a> : " "}
                    {item.instagram_link ? <a href={item.instagram_link}>
                      <FaInstagram className="icon-instagram" />
                    </a> : " "}
                    {item.github_link ? <a href={item.github_link}>
                      <FaGithub className="icon-github" />
                    </a> : " "}
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        )}
      </section>
    </>
  )
}

export default Team
import { useRef } from "react";
import { useQuery, useMutation } from "react-query";

import { useDispatch } from "react-redux";
import { Form, Input, Divider } from "antd";
import "./StudentSettingsTab.scss";
import { openNotification } from "./../../shared/Notification";
import {
  updatePassword as updatePasswordApi,
  updateUserName as updateUserNameApi,
  fetchCurrentUser,
} from "./../../../api";

import { setUserInfo } from "../../../redux";

const StudentSettingsTab = () => {
  const userFormRef = useRef();
  const passwordFormRef = useRef();
  const { mutate: updatePassword } = useMutation(updatePasswordApi, {
    onSuccess: () =>
      openNotification({
        title: "عملية صحيحة",
        message: "لقد تم تغير كلمة المرور بنجاح",
        isNotError: true,
      }),
    onError: () =>
      openNotification({
        title: "كلمة المرور خطأ",
        message: "برجاء ادخال كلمة المرور الصحيحة",
      }),
  });
  const { mutate: updateUserName } = useMutation(updateUserNameApi, {
    onSuccess: () => {
      refetchUserData();
      openNotification({
        title: "عملية صحيحة",
        message: "لقد تم تغير معلوماتك بنجاح",
        isNotError: true,
      });
    },
  });

  const updatePasswordHandler = () => {
    const passwordData = passwordFormRef.current.getFieldsValue();
    if (passwordData.new_password !== passwordData.new_password_confirmation)
      return openNotification({
        title: "تاكيد كلمة المرور غير صحيحة",
        message: "برجاء ادخال كلمة المرور وتاكيد كلمة المرور مطابقتان",
      });

    if (passwordData.new_password.length < 6)
      return openNotification({
        title: "كلمة المرور قصيرة",
        message: "برجاء ادخال كلمة مرور مكونة من 6 احرف فأكثر",
      });

    updatePassword(passwordData);
  };

  const updateUserNameHandler = () => {
    const userData = userFormRef.current.getFieldsValue();
    updateUserName(userData);
  };

  const {
    data: userData,
    isLoading,
    refetch: refetchUserData,
  } = useQuery("current-user", fetchCurrentUser, {
    onSuccess: data => dispatch(setUserInfo(data.data.data)),
  });
  const user = userData?.data?.data;
  const dispatch = useDispatch();

  return (
    <div className="student-settings-tab">
      {!isLoading ? (
        <Form ref={userFormRef} onFinish={updateUserNameHandler}>
          <div>
            <header className="student-settings__item--header">
              <h5>تغيير الأسم</h5>
            </header>
            <Form.Item
              name="first_name"
              label="الأسم الأول"
              colon={false}
              rules={[
                {
                  required: true,
                  message: "الأسم الأول مطلوب برجاء ملئ الحقل",
                },
                {
                  max: 15,
                  message: "يجب إدخال اقل من 15 حرف",
                },
              ]}
            >
              <Input id="first_name" placeholder={user?.first_name} />
            </Form.Item>
            <Form.Item
              name="last_name"
              label="الأسم الأخير"
              colon={false}
              rules={[
                {
                  required: true,
                  message: "الأسم الأخير مطلوب برجاء ملئ الحقل",
                },
                {
                  max: 15,
                  message: "يجب إدخال اقل من 15 حرف",
                },
              ]}
            >
              <Input id="last_name" placeholder={user?.last_name} />
            </Form.Item>
          </div>
          <div className="d-flex justify-content-end">
            <button type="submit" className="btn btn-submit">
              حفظ الأسم الجديد
            </button>
          </div>
        </Form>
      ) : (
        ""
      )}

      <Divider />
      <Form ref={passwordFormRef} onFinish={updatePasswordHandler}>
        <header className="student-settings__item--header">
          <h5>تغيير الرقم السري</h5>
        </header>
        <Form.Item
          name="old_password"
          label="الرقم السري القديم"
          colon={false}
          rules={[
            {
              required: true,
              message: "الرقم السري القديم مطلوب!",
            },
          ]}
        >
          <Input id="old_password" type="password" />
        </Form.Item>
        <Form.Item
          name="new_password"
          label="الرقم السري الجديد"
          colon={false}
          rules={[
            {
              required: true,
              message: "الرقم السري الجديد مطلوب!",
            },
          ]}
        >
          <Input id="new_password" type="password" />
        </Form.Item>
        <Form.Item
          name="new_password_confirmation"
          label="تأكيد الرقم السري"
          colon={false}
          rules={[
            {
              required: true,
              message: "تأكيد الرقم السري مطلوب!",
            },
          ]}
        >
          <Input id="new_password_confirmation" type="password" />
        </Form.Item>
        <div className="d-flex justify-content-end">
          <button type="submit" className="btn btn-submit">
            حفظ الرقم السري الجديد
          </button>
        </div>
      </Form>
    </div>
  );
};

export default StudentSettingsTab;

import { notification } from "antd";
export const openNotification = ({ message, description, isNotError }) => {
  const args = {
    message,
    description,
    duration: 10,
    placement: "top",
    style: {
      backgroundColor: '#fff', 
      border: isNotError ? '1px solid #b7eb8f' : '1px solid #ffa39e', 
      color: isNotError ? '#389e0d' : '#cf1322',
      borderRadius: '8px',
      minWidth : '400px'
    },
  };
  

  isNotError ? notification.success(args) : notification.error(args);
};

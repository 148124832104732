import React from 'react';
import { Modal} from 'react-bootstrap';
import "./SharedModal.scss"
const SharedModal = ({ show, handleClose, title, body, footerButtons , className}) => {
  return (
    <Modal className='SharedModal' show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className='SharedModal__title'>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='SharedModal__desc'>{body}</Modal.Body>
      <Modal.Footer>
        {footerButtons.map((button, index) => (
          <button
            key={index}
            onClick={button.onClick}
            className={button.className}
          >
            {button.label}
          </button>
        ))}
      </Modal.Footer>
    </Modal>
  );
};

export default SharedModal;